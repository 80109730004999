import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SecondaryLayout from "../components/common/secondary-layout"
import { Blog } from "../models/blog.model"
import BlogCard from "../components/blog/card"
import ReactPaginate from 'react-paginate';

/*
const BlogPage = () => {
  const { allStrapiBlog }: { allStrapiBlog: { nodes: Blog[] } } = useStaticQuery(graphql`
    query {
      allStrapiBlog {
        nodes {
         id
         createdAt
         title
          writter {
            name
            email
          }
          thumbnail {
            alternativeText
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)


	const items = allStrapiBlog.nodes;
	const [itemOffset, setItemOffset] = React.useState(0);
	const endOffset = itemOffset + 3;
	const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / 3);

	const handlePageClick = (event: any) => {
    const newOffset = (event.selected * 3) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <SecondaryLayout
      side={
        <div className="privacy-main-container--side">
          <h2 className="privacy-main-container--side__title">Blog</h2>
          <p className="privacy-main-container--side__description">
            Zenpli's blog features content on our partnerships, case studies, thought leadership, and product philosophy.
          </p>
        </div>
      }
      main={
        <div className="privacy-main-container--content">
          {currentItems.map((blog) => (
            <BlogCard key={blog.id} blog={blog} />
          ))}
					<div className="privacy-main-container__paginator-container">
						<ReactPaginate
							className="privacy-main-container__paginator"
							breakLabel="..."
							nextLabel=">"
							pageRangeDisplayed={1}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							previousLabel="<"
							onPageChange={handlePageClick}
						/>
					</div>
        </div>
      }
    />
  )
}
*/

const BlogPage = () => <></>

export const Head = () => (
  <>
    <title>Zenpli » Blog</title>
    <meta name="description" content="Zenpli » Blog" />
  </>
)

export default BlogPage